<template>
  <div class="authorization">

    <div class="authorization-content row">
      <div class="col-12 authorization-content-card">
        <div class="row justify-content-center ">
          <div class="col-10 col-md-8 col-lg-6 col-xl-5">
            <div class="card shadow">
              <div class="card-header text-center">
                <h5 v-text="$t('messages.accountAuthorization')"></h5>
              </div>
              <div class="card-body authorization-content-message d-flex flex-column justify-content-between">
                <p class="my-3 text-center" v-html="$t('messages.alreadyAccount')"></p>
                <div class="row justify-content-center">
                  <div class="col-auto">
                    <div class="rounded-pill border bg-black text-white py-1 px-3">
                      <p class=" f-18 text-center">
                        <em class="fa fa-user-circle fa-lg mr-2"></em>
                        <strong v-text="$route.query.email"> </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-center">
                  <div class="col-6 col-lg-4">
                    <b-button variant="light w-100 "
                      :to="{ path: '/signup', query: { site_id: $route.query.site_id, redirect_url: $route.query.redirect_url }}"
                      v-text="$t('dashboard.payments.newAccount')">
                    </b-button>
                  </div>
                  <div class="col-6 col-lg-4">
                    <b-button variant="success w-100 " @click="fnApiAuthorizationAccount()"
                      v-text="$t('Continue')">
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        account: {
          account: null,
          siteId: null,
          redirect_url: null,
        }
      }
    },
    methods: {
      fnApiAuthorizationAccount() {
        if (('site_id' in this.$route.query) && ('account' in this.$route.query) && ('redirect_url' in this.$route
            .query)) {
          let data = {
            site_id: this.$route.query.site_id,
            account: this.$route.query.account,
            redirect_url: this.$route.query.redirect_url,
            auth: true,
            uri: window.location.href,
          }
          axiosAccount.post('/api/accounts/login/existing', data).then(response => {
            window.location.replace(response.data);
          });
        } else {
          alert('Miss information');
        }
      }
    },
  }
</script>

<style lang="scss">
  .authorization {
    background-color: gray;
    padding-top: 25vh;

    .authorization-content {
      min-height: 75vh;
      background-color: white;

      .authorization-content-card {
        transform: translateY(-10vh);
      }

      .authorization-content-message {
        min-height: 25vh;
      }

    }

  }
</style>